import React from 'react';

import './Footer.css';

function Footer() {
	
	return (
		<footer className="bg-dark centre ">&copy; {new Date().getFullYear()}, Rehan Hajee</footer>
	);
	
	
}

export default Footer;
